import React from "react";
import SmallButtonIcon from "../buttons/SmallButtonIcon";
import { colors } from "../../theme/Colors";

const TopNavBar = ({ onClick }) => {
  return (
    <div
      style={{
        height: 100,
        width: "100%",
        position: "fixed",
        top: 0,
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 20,
      }}
    >
      <img
        alt="load logo"
        src={require("../../images/newLogoJob.png")}
        style={{ height: 160, marginLeft: 20 }}
      />

      <div style={{ flex: 1 }} />
      <div
        style={{
          width: 100,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SmallButtonIcon
          isMenu={true}
          image={require("../../images/menuIconColor.png")}
          image2={require("../../images/menuWhiteIcon.png")}
          backgroundColor={colors.secondary}
          backgroundColor2={colors.secondary}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default TopNavBar;
