import React, { useState } from "react";
import { colors } from "../../theme/Colors";

const ServiceInfoBlock = ({
  title,
  description,
  icon,
  width,
  open,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: open ? (width > 899 ? 450 : "100%") : width > 899 ? 150 : 80,
        height: open ? (width > 899 ? null : null) : width > 899 ? 150 : 100,
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        // marginBottom: 20,width > 899 ?
        transition: "all 0.5s",
        overflow: "hidden",
        margin: width > 899 ? 20 : 5,
      }}
    >
      <div
        onClick={onClick}
        style={{
          height: "90%",
          width: width > 899 ? "90%" : "90%",
          borderStyle: "solid",
          borderRadius: 10,
          borderWidth: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: hover ? colors.primary : colors.secondary,
          flexDirection: width > 899 ? "column" : "column",
          backgroundColor: hover
            ? open
              ? colors.secondary
              : colors.secondary
            : open
            ? colors.secondary
            : "#FFFFFF66",
        }}
      >
        <img
          alt="icon"
          src={icon}
          style={{ height: open ? 80 : 40, marginTop: open ? 40 : 0 }}
        />
        <div
          style={{
            fontSize: open ? (width > 899 ? 24 : 20) : width > 899 ? 14 : 10,
            fontWeight: "bold",
            marginTop: 10,
            // marginBottom: 10,
            color: hover ? "#FFFFFF" : open ? "#FFFFFF" : colors.secondary,
            textAlign: "center",
            marginBottom: open ? 10 : null,
          }}
        >
          {title}
        </div>
        {open ? (
          <div
            style={{
              fontSize: 12,
              width: "90%",
              textAlign: "center",
              marginBottom: 20,
              // height: width > 899 ? 400 : null
              backgroundColor: open ? null : "#FFFFFF66",
              padding: open ? 5 : 10,
              borderRadius: 14,
              color: open ? "#FFFFFF" : null,
            }}
          >
            {description}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ServiceInfoBlock;
