import React, { useState } from "react";

const SmallButtonIcon = ({
  image,
  backgroundColor,
  onClick,
  change,
  image2,
  backgroundColor2,
  label,
  height,
  width,
  fontColor,
  fontSize,
  borderRadius,
  isMenu,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
      style={{
        height: height ? height : 60,
        width: width ? width : 60,
        // borderStyle: "solid",
        borderRadius: borderRadius ? borderRadius : 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: change
          ? backgroundColor2
          : hover
          ? backgroundColor
          : isMenu
          ? "#FFFFFF"
          : backgroundColor,
        overflow: "hidden",
        transition: "all 0.4s",
        cursor: "pointer",
      }}
    >
      {label ? (
        <div
          style={{ color: fontColor, fontSize: fontSize, fontWeight: "bold" }}
        >
          {label}
        </div>
      ) : (
        <img
          alt="icon"
          src={change ? image2 : hover ? image2 : image}
          style={{ height: 45 }}
        />
      )}
    </div>
  );
};

export default SmallButtonIcon;
