import React, { useEffect, useState } from "react";
import { ContactUsDisplay, MenuPopUp, TopNavBar } from "../../components";

const ContactUs = ({ width, height, setMenu, menu }) => {
  useEffect(() => {
    setMenu(false);
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <TopNavBar onClick={() => setMenu(menu ? false : true)} />
      <ContactUsDisplay
        title={"Occupational health solutions"}
        question={"NEED A MEDICAL"}
        width={width}
        height={"100%"}
      />
      <MenuPopUp
        open={menu}
        height={height}
        onAboutClick={() => {
          //   setSelectedScreen("about");
          //   setMenu(false);
        }}
        onMedicalClick={() => {
          //   setSelectedScreen("contact");
          //   setMenu(false);
        }}
        onServiceClick={() => {
          //   setSelectedScreen("service");
          //   setMenu(false);
        }}
      />
    </div>
  );
};

export default ContactUs;
