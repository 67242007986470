import React, { useState } from "react";
import { colors } from "../../theme/Colors";

const ButtonCore = ({
  label,
  backgroundColor,
  fontColor,
  icon,
  backGroundColorTwo,
  onClick,
  height,
  size,
  ghost,
  fontColor2,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: 60,
        width: size ? size : 220,
        // borderStyle: "solid",
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: hover ? backgroundColor : backGroundColorTwo,
        transition: "all 0.6s",
        borderStyle: ghost ? "solid" : null,
        borderWidth: 2,
        borderColor: colors.primary,
        cursor: "pointer",
      }}
    >
      {icon ? (
        <img
          alt="icon"
          src={icon}
          style={{ height: height ? height : 40, marginLeft: 10 }}
        />
      ) : null}

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 20,
          fontWeight: "bold",
          color: ghost ? colors.primary : hover ? fontColor2 : fontColor,
          textDecoration: "none",
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default ButtonCore;
