import React from "react";
import ButtonCore from "../buttons/ButtonCore";
import { colors } from "../../theme/Colors";

const AboutUsDisplay = ({ title, question, width, height }) => {
  return (
    <div
      style={{
        height: height,
        width: "100%",
        // borderStyle: "solid",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        transition: "height 0.8s",
        position: "relative",
      }}
    >
      <div
        style={{
          marginTop: 100,
          fontWeight: "bold",
          fontSize: width > 899 ? 50 : 30,
          color: colors.secondary,
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: colors.secondary,
          width: "80%",
          textAlign: "center",
          marginBottom: 10,
          fontSize: width > 899 ? 26 : 26,
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        {question}
      </div>

      <div
        style={{
          height: "80%",
          width: "80%",

          justifyContent: "space-evenly",
          alignItems: "center",
          display: "flex",
          flexDirection: width > 899 ? "column" : "column",
          zIndex: 10,
        }}
      >
        <p
          style={{
            width: width > 899 ? "50%" : "95%",
            textAlign: "center",
            // color: colors.secondary,
            fontWeight: "600",
            borderStyle: "solid",
            padding: width > 899 ? 40 : 20,
            borderRadius: 20,
            borderWidth: 5,
            borderColor: colors.primary,
            color: colors.secondary,
          }}
        >
          The relationship between work and health is directly related, where
          the working environment may have an effect on the health of the
          employee and his/her ability to perform their tasks. This may be
          related to dust, noise, and other risks on site. We perform various
          tests to measure the effect of these risks on the employee. This helps
          to protect both the employee and the employer. We can do these tests
          on any employees in all sectors.
        </p>
        <img
          alt="background display"
          src={require("../../images/aboutUsIcon.png")}
          style={{ height: width > 899 ? 200 : 160 }}
        />
      </div>
      <img
        alt="background display"
        src={require("../../images/bottomPurple.png")}
        style={{ width: "100%", position: "absolute", bottom: 0, zIndex: 0 }}
      />
    </div>
  );
};

export default AboutUsDisplay;
