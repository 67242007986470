import React, { useEffect } from "react";
import {
  AboutUsDisplay,
  ContactUsDisplay,
  MenuPopUp,
  TopNavBar,
} from "../../components";

const AboutUs = ({ width, height, setMenu, menu }) => {
  useEffect(() => {
    setMenu(false);
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <TopNavBar onClick={() => setMenu(menu ? false : true)} />
      <AboutUsDisplay
        // title={"NEED A MEDICAL"}
        question={"ABOUT US"}
        width={width}
        height={"100%"}
      />

      <MenuPopUp
        open={menu}
        height={height}
        // onAboutClick={() => {
        //   setMenu(false);
        // }}
        // onMedicalClick={() => {
        //   setMenu(false);
        // }}
        // onServiceClick={() => {
        //   setMenu(false);
        // }}
      />
    </div>
  );
};

export default AboutUs;
