import React, { useState } from "react";
import ButtonCore from "../buttons/ButtonCore";
import { colors } from "../../theme/Colors";
import EmailPopUp from "../email-pop-up/EmailPopUp";
import SmallButtonIcon from "../buttons/SmallButtonIcon";

const ContactUsDisplay = ({ title, question, width, height }) => {
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [emailOptions, setEmailOptions] = useState(true);
  const [number, setNumber] = useState(false);
  const [succesText, setSuccessText] = useState("LOADING . . .");
  return (
    <div
      style={{
        height: height,
        width: "100%",
        // borderStyle: "solid",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        transition: "height 0.8s",
      }}
    >
      {width > 899 ? (
        <div
          style={{
            marginTop: 100,
            fontWeight: "bold",
            fontSize: width > 899 ? 50 : 28,
            color: colors.secondary,
            textAlign: "center",
          }}
        >
          {title}
        </div>
      ) : (
        <div
          style={{
            marginTop: 100,
            fontWeight: "bold",
            fontSize: width > 899 ? 50 : 28,
            color: colors.secondary,
            textAlign: "center",
          }}
        >
          {title}
        </div>
      )}

      <div
        style={{
          height: "80%",
          width: "80%",

          justifyContent: "space-evenly",
          alignItems: width > 599 ? "center" : "center",
          display: "flex",
          flexDirection: width > 599 ? "column" : "column",
          zIndex: 10,
          // paddingTop: width > 599 ? 150 : 0,
          // flexWrap: "wrap",
          marginTop: 10,
        }}
      >
        <a
          href="https://wa.me/+27716879779"
          style={{ textDecorationLine: "none" }}
        >
          <ButtonCore
            size={240}
            label={"Whatsapp"}
            backgroundColor={colors.primary}
            backGroundColorTwo={colors.secondary}
            fontColor={"#FFFFFF"}
            icon={require("../../images/whatsappWhite.png")}
            fontColor2={"#FFFFFF"}
          />
        </a>

        <ButtonCore
          size={240}
          label={"Email"}
          backgroundColor={colors.primary}
          backGroundColorTwo={colors.secondary}
          fontColor={"#FFFFFF"}
          icon={require("../../images/whiteMail.png")}
          onClick={() => setEmailPopUp(true)}
          fontColor2={"#FFFFFF"}
        />

        <a href="tel:+27716879779" style={{ textDecorationLine: "none" }}>
          <ButtonCore
            size={240}
            label={number ? "+27716879779" : "Contact number"}
            backgroundColor={colors.primary}
            backGroundColorTwo={colors.secondary}
            fontColor={"#FFFFFF"}
            icon={require("../../images/contactNUmber.png")}
            onClick={() => setNumber(number ? false : true)}
            fontColor2={"#FFFFFF"}
          />
        </a>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <a
            href="https://www.facebook.com/BecauseHealthWorks/"
            style={{ textDecorationLine: "none" }}
            target="_blank"
          >
            <SmallButtonIcon
              backgroundColor={colors.secondary}
              backGroundColorTwo={colors.secondary}
              fontColor={"#FFFFFF"}
              image={require("../../images/faceBookWhite.png")}
              image2={require("../../images/faceBookWhite.png")}
              fontColor2={"#FFFFFF"}
              borderRadius={100}
            />
          </a>
          <a
            style={{ textDecorationLine: "none" }}
            href="https://www.instagram.com/jobmed84?igsh=eHp6Zmc5a2tmZW5k&utm_source=qr"
            target="_blank"
          >
            {" "}
            <SmallButtonIcon
              backgroundColor={colors.secondary}
              backGroundColorTwo={colors.secondary}
              fontColor={"#FFFFFF"}
              image={require("../../images/instaWHite.png")}
              image2={require("../../images/instaWHite.png")}
              fontColor2={"#FFFFFF"}
              borderRadius={100}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/jobmed-occupational-health/"
            target="_blank"
            style={{ textDecorationLine: "none" }}
          >
            <SmallButtonIcon
              // size={240}
              // label={"LinkedIn"}
              backgroundColor={colors.secondary}
              backGroundColorTwo={colors.secondary}
              fontColor={"#FFFFFF"}
              image={require("../../images/linkedInWhite.png")}
              image2={require("../../images/linkedInWhite.png")}
              fontColor2={"#FFFFFF"}
              borderRadius={100}
            />
          </a>
          <a
            href="https://www.google.com/search?q=jobmed&sca_esv=09fdf5ad3c7db8b8&rlz=1C5CHFA_enZA1071ZA1071&sxsrf=ACQVn08fOScR70i1aKtPaLrkO0pcYrgSNw%3A1710345663248&ei=v83xZfjZDu-Fxc8P_bSs8A8&ved=0ahUKEwi4tc2NzvGEAxXvQvEDHX0aC_4Q4dUDCBA&uact=5&oq=jobmed&gs_lp=Egxnd3Mtd2l6LXNlcnAiBmpvYm1lZDIKECMYgAQYigUYJzIEECMYJzIEECMYJzIQEAAYgAQYigUYQxixAxiDATILEC4YgAQYxwEYrwEyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABEiaGVDHC1jKGHABeAGQAQCYAbwCoAHoCKoBBTItMy4xuAEDyAEA-AEBmAIFoAKSCcICBxAjGLADGCfCAgoQABhHGNYEGLADwgINEAAYgAQYigUYQxiwA8ICGRAuGIAEGIoFGEMYxwEY0QMYyAMYsAPYAQHCAgsQABiABBiKBRiRAsICCxAuGIAEGIoFGJECwgILEAAYgAQYsQMYgwHCAgQQABgDwgIGEAAYAxgKwgIKEAAYgAQYigUYQ8ICEBAuGIAEGIoFGEMYxwEY0QPCAg4QABiABBixAxiDARiSA8ICCxAAGIAEGIoFGJIDwgITEAAYgAQYigUYQxixAxiDARjJA5gDAIgGAZAGEroGBggBEAEYCJIHBzEuMC4zLjGgB94t&sclient=gws-wiz-serp#ip=1"
            target="_blank"
            style={{ textDecorationLine: "none" }}
          >
            <SmallButtonIcon
              // size={240}
              // label={"LinkedIn"}
              backgroundColor={colors.secondary}
              backGroundColorTwo={colors.secondary}
              fontColor={"#FFFFFF"}
              image={require("../../images/googleWhite.png")}
              image2={require("../../images/googleWhite.png")}
              fontColor2={"#FFFFFF"}
              borderRadius={100}
            />
          </a>
        </div>
      </div>
      <img
        alt="background display"
        src={require("../../images/bottomimage.png")}
        style={{ width: "100%", position: "absolute", bottom: 0, zIndex: 0 }}
      />
      <EmailPopUp
        width={width}
        height={height}
        display={emailPopUp}
        onCloseClick={() => {
          setEmailPopUp(false);
          setEmailOptions(true);
          setSuccessText("LOADING . . .");
        }}
        onEmailClick={() => {
          navigator.clipboard.writeText("info@jobmed.co.za");
          setEmailPopUp(false);
        }}
        setEmailPopUp={setEmailPopUp}
        emailOptions={emailOptions}
        setEmailOptions={setEmailOptions}
        succesText={succesText}
        setSuccessText={setSuccessText}
      />
    </div>
  );
};

export default ContactUsDisplay;
