import React, { useEffect } from "react";
import {
  AboutUsDisplay,
  ContactUsDisplay,
  MenuPopUp,
  ServiceDisplay,
  TopNavBar,
} from "../../components";

const Services = ({ width, height, setMenu, menu }) => {
  useEffect(() => {
    setMenu(false);
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <TopNavBar onClick={() => setMenu(menu ? false : true)} />
      <ServiceDisplay
        // title={"NEED A MEDICAL"}
        question={"Services"}
        width={width}
        height={"100%"}
      />

      <MenuPopUp
        open={menu}
        height={height}
        onAboutClick={() => {
          //   setSelectedScreen("about");
          // setMenu(false);
        }}
        onMedicalClick={() => {
          //   setSelectedScreen("contact");
          //   setMenu(false);
        }}
        onServiceClick={() => {
          //   setSelectedScreen("service");
          //   setMenu(false);
        }}
      />
    </div>
  );
};

export default Services;
