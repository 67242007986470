import React from "react";
import { colors } from "../../theme/Colors";
import ButtonCore from "../buttons/ButtonCore";
import { Outlet, Link } from "react-router-dom";

const MenuPopUp = ({
  height,
  open,
  onServiceClick,
  onMedicalClick,
  onAboutClick,
}) => {
  return (
    <div
      style={{
        width: open ? 250 : 0,
        height: height - 110,
        position: "fixed",
        right: 0,
        overflow: "hidden",
        backgroundColor: colors.primary,
        transition: "width 0.6s",
        zIndex: 10,
        top: 100,
        borderTopLeftRadius: 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ height: 10 }} />
      <Link to="/services" style={{ textDecoration: "none" }}>
        <ButtonCore
          onClick={onServiceClick}
          label={"SERVICES"}
          height={50}
          backGroundColorTwo={colors.primary}
          backgroundColor={colors.secondary}
          fontColor={"#FFFFFF"}
          icon={require("../../images/servicesIconWhite.png")}
        />
      </Link>

      <div style={{ height: 10 }} />
      <Link to="/about" style={{ textDecoration: "none" }}>
        <ButtonCore
          onClick={onAboutClick}
          label={"ABOUT US"}
          backGroundColorTwo={colors.primary}
          backgroundColor={colors.secondary}
          fontColor={"#FFFFFF"}
          icon={require("../../images/aboutUsIocnWhite.png")}
        />
      </Link>

      <div style={{ height: 10 }} />
      <Link to="/" style={{ textDecoration: "none" }}>
        <ButtonCore
          onClick={onMedicalClick}
          label={"CONTACT US"}
          backGroundColorTwo={colors.primary}
          backgroundColor={colors.secondary}
          fontColor={"#FFFFFF"}
          icon={require("../../images/whatsappWhite.png")}
        />
      </Link>
    </div>
  );
};

export default MenuPopUp;
