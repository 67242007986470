import React from "react";
import emailjs from "@emailjs/browser";
import ButtonCore from "../../buttons/ButtonCore";
import { colors } from "../../../theme/Colors";

export default function EmailComponent({
  setFormsDrop,
  width,
  onCloseClick,
  setEmailOptions,
  setSuccessText,
}) {
  function sendEmail(e) {
    e.preventDefault();
    setEmailOptions(false);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        "template_jk4ejae",
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccessText("Your message has been sent successfully");
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <form
      className="contact-form"
      onSubmit={sendEmail}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <h2
        style={{
          color: "#FFFFFF",
          fontSize: 30,
          marginBottom: 10,
          width: "100%",
          textAlign: "center",
        }}
      >
        CONTACT US:
      </h2>
      <label
        style={{
          width: width > 899 ? 300 : 260,
          fontWeight: "bold",
          fontSize: 16,
          color: "#FFFFFF",
        }}
      >
        Contact Person:
      </label>
      <input
        style={{
          height: 30,
          width: width > 899 ? 300 : 260,
          marginTop: 10,
          borderRadius: 50,
          padding: 10,
          paddingLeft: 20,
          fontSize: 18,

          borderWidth: 0.2,
        }}
        type="text"
        name="contact_name"
      />
      <label
        style={{
          width: width > 899 ? 300 : 260,
          fontWeight: "bold",
          fontSize: 16,
          color: "#FFFFFF",
          marginTop: 10,
        }}
      >
        Cell Number:
      </label>
      <input
        style={{
          height: 30,
          width: width > 899 ? 300 : 260,
          marginTop: 10,
          borderRadius: 50,
          padding: 10,
          paddingLeft: 20,
          fontSize: 18,

          borderWidth: 0.2,
        }}
        type="text"
        name="cell_number"
      />
      <label
        style={{
          width: width > 899 ? 300 : 260,
          fontWeight: "bold",
          fontSize: 16,
          color: "#FFFFFF",
          marginTop: 10,
        }}
      >
        Email Address:
      </label>
      <input
        style={{
          height: 30,
          width: width > 899 ? 300 : 260,
          marginTop: 10,
          borderRadius: 50,
          padding: 10,
          paddingLeft: 20,
          fontSize: 18,

          borderWidth: 0.2,
        }}
        name="email_address"
        type="email"
      />
      <label
        style={{
          width: width > 899 ? 300 : 260,
          fontWeight: "bold",
          fontSize: 16,
          color: "#FFFFFF",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        How Can We Assist?
      </label>
      <textarea
        style={{
          marginBottom: 10,
          width: width > 899 ? 310 : 270,
          height: "130px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          borderWidth: 0.2,
          paddingLeft: 10,
          fontSize: 16,
          paddingTop: 10,
          //   borderStyle: "solid",
        }}
        type="text"
        name="message"
      />

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 10,
        }}
      >
        <ButtonCore
          label={"CANCEL"}
          ghost={true}
          size={width > 899 ? 180 : 140}
          onClick={onCloseClick}
        />
        <div style={{ width: 20 }} />
        <input
          style={{
            height: "60px",
            borderRadius: 50,
            backgroundColor: colors.primary,
            color: "#FFFFFF",
            // marginTop: "20px",
            fontWeight: "bold",
            fontSize: "20px",
            width: width > 899 ? 180 : 140,
            borderWidth: 0,
          }}
          type="submit"
          value="SUBMIT"
        />
      </div>
    </form>
  );
}

// - company name
// - Address
// -  name
// -  landline number
// -  Cell number
// -  information you require sales representative to present to you
