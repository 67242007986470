import React, { useState } from "react";
import ButtonCore from "../buttons/ButtonCore";
import { colors } from "../../theme/Colors";
import ServiceInfoBlock from "../service-info-block/ServiceInfoBlock";
import EmailPopUp from "../email-pop-up/EmailPopUp";

const ServiceDisplay = ({ title, question, width, height }) => {
  const [info, setInfo] = useState("none");
  return (
    <div
      style={{
        height: height,
        width: "100%",
        // borderStyle: "solid",
        // backgroundColor: colors.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "scroll",
        transition: "height 0.8s",
        position: "relative",
        // borderTopStyle: "solid",
      }}
    >
      <div
        style={{
          marginTop: 80,
          fontWeight: "bold",
          fontSize: width > 899 ? 50 : 30,
          color: colors.secondary,
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: colors.secondary,
          width: "80%",
          textAlign: "center",
          marginBottom: 10,
          fontSize: width > 899 ? 26 : 26,
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        {question}
      </div>

      <div
        style={{
          height: "80%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          // flexDirection: width > 899 ? "column" : "column",
          zIndex: 10,
          flexWrap: "wrap",
        }}
      >
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "medicalSer")}
          open={info === "medicalSer" ? true : false}
          width={width}
          title={"Medical Surveilance"}
          description={
            "Ensure that all clients are fit and healthy at work by the implementation of entry, periodic, pre-placement, post-illness, and exit medicals. In order to create an environment that is safe and without risk to the health of themselves and their fellow employees."
          }
          icon={require("../../images/heartBeatGreen.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "vaccines")}
          open={info === "vaccines" ? true : false}
          width={width}
          title={"Vaccines"}
          description={
            "Vaccination is the safe and most effective way to prevent infection and minimize severe outcomes."
          }
          icon={require("../../images/vaccineGreenIcon.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "travelmedicine")}
          open={info === "travelmedicine" ? true : false}
          width={width}
          title={"Travel medicine"}
          description={
            "A qualified travel medicine sister can assist with any vaccination needed for work or leisure to keep you safe and healthy on your travels."
          }
          icon={require("../../images/travelMedical.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "mobileMedicals")}
          open={info === "mobileMedicals" ? true : false}
          width={width}
          title={"Mobile medicals"}
          description={
            "Two mobile units are available to conduct medical surveillance on sites nationwide which you can choose from. The first unit is a 4-ton truck that contains 2 audiometric booths and will require an electrical point. The other unit has noise-canceling audiometric equipment(negates the use of an audiometric booth) that wouldn't require an electrical point this unit requires a room on site."
          }
          icon={require("../../images/mobileMedicine.png")}
        />
        <ServiceInfoBlock
          onClick={() =>
            setInfo(info !== "none" ? "none" : "primaryHealthcare")
          }
          open={info === "primaryHealthcare" ? true : false}
          width={width}
          title={"Primary healthcare"}
          description={
            "Primary healthcare provides continuing and comprehensive healthcare for individuals. The service includes acute, chronic, and preventative medical care. as well as examination, diagnosis, and treatment."
          }
          icon={require("../../images/primaryHealthcare.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "onsiteClinic")}
          open={info === "onsiteClinic" ? true : false}
          width={width}
          title={"On-site Clinics"}
          description={
            "Management of on-site clinics(nationwide) which includes, Occupational health, Primary Healthcare as per site requirements. With the utilization of MMS, allows the client to have a fully integrated Medical Management Solution."
          }
          icon={require("../../images/onsiteClinic.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "training")}
          open={info === "training" ? true : false}
          width={width}
          title={"Training"}
          description={
            "A holistic integrated risk-based management training solution."
          }
          icon={require("../../images/training.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "MMS")}
          open={info === "MMS" ? true : false}
          width={width}
          title={"MMS"}
          description={
            "Our medicals are electronically captured, digitally signed, and securely stored. Diagnostic screening results feed directly into the management system. Client and patient upload integrated with the diagnostic software."
          }
          icon={require("../../images/mms.png")}
        />
        <ServiceInfoBlock
          onClick={() =>
            setInfo(info !== "none" ? "none" : "chronicConditionManagement")
          }
          open={info === "chronicConditionManagement" ? true : false}
          width={width}
          title={"Chronic condition management"}
          description={
            "A chronic condition is a health condition that is long-lasting. Jobmed can assist by managing chronic conditions through monthly visits to ensure the condition is controlled and to prevent complications."
          }
          icon={require("../../images/ChronicConditionManagement.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "COID")}
          open={info === "COID" ? true : false}
          width={width}
          title={"COID"}
          description={
            "Our team can assist you with all COID and Occupational related diseases processes to ensure that employees are covered against occupational diseases, injuries, and death."
          }
          icon={require("../../images/Coid.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "VCT")}
          open={info === "VCT" ? true : false}
          width={width}
          title={"VCT"}
          description={
            "Our qualified staff assists in voluntary counseling and testing for individuals."
          }
          icon={require("../../images/vct.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "Counseling")}
          open={info === "Counseling" ? true : false}
          width={width}
          title={"Counseling"}
          description={
            "Counseling can empower you. Our qualified counselor can assist with emotional support and possible solutions to your problems."
          }
          icon={require("../../images/counselling.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "Employee")}
          open={info === "Employee" ? true : false}
          width={width}
          title={"Employee well-ness Day"}
          description={
            "Prioritize your employee's well-being is the main way an employer can help employees manage stress. We can assist in planning an annual wellness day for your employees to improve their health and well-being."
          }
          icon={require("../../images/employeeWellness.png")}
        />
        <ServiceInfoBlock
          onClick={() => setInfo(info !== "none" ? "none" : "Drug")}
          open={info === "Drug" ? true : false}
          width={width}
          title={"Drug Testing"}
          description={
            "We offer screening drug tests that look for signs of one or more illegal or prescription drugs in a urine sample. We can assist in the rehabilitation process."
          }
          icon={require("../../images/drugTesting.png")}
        />
        <div style={{ height: 20 }} />
      </div>
      {/* <img
        alt="background display"
        src={require("../../images/bottomPurple.png")}
        style={{
          width: "100%",
          position: "absolute",
          bottom: width > 899 ? 0 : -60,
          zIndex: 0,
        }}
      /> */}
    </div>
  );
};

export default ServiceDisplay;
