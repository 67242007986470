import React, { useState } from "react";
import { colors } from "../../theme/Colors";
import ButtonCore from "../buttons/ButtonCore";
import SmallButtonIcon from "../buttons/SmallButtonIcon";
import EmailComponent from "./atoms/EmailCompnent";

const EmailPopUp = ({
  width,
  height,
  display,
  onCloseClick,
  onEmailClick,
  setEmailPopUp,
  emailOptions,
  setEmailOptions,
  succesText,
  setSuccessText,
}) => {
  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        position: "absolute",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 30,
        transition: "height 0.8s",
      }}
    >
      <div
        style={{
          height: width > 899 ? (emailOptions ? "90%" : "50%") : "100%",
          width: width > 899 ? "40%" : "100%",
          borderRadius: 10,
          backgroundColor: colors.secondary,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: emailOptions ? "flex-start" : "center",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <SmallButtonIcon
            label={"X"}
            height={30}
            width={30}
            borderRadius={50}
            onClick={onCloseClick}
            fontColor={colors.primary}
            fontSize={20}
          />
        </div>
        {emailOptions ? (
          <div
            style={{
              overflow: "scroll",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <EmailComponent
              setEmailOptions={setEmailOptions}
              width={width}
              onCloseClick={onCloseClick}
              setFormsDrop={setEmailPopUp}
              setSuccessText={setSuccessText}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                fontWeight: "bold",
                color: "#FFFFFF",
                fontSize: succesText.length > 20 ? 24 : 30,
                width: "80%",
                textAlign: "center",
                marginBottom: 20,
              }}
            >
              {succesText}
            </div>
            {succesText.length > 18 ? (
              <ButtonCore label={"CLOSE"} onClick={onCloseClick} ghost={true} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default EmailPopUp;
